import React, { useMemo, useRef, useState } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import OverendCommentary from "./common/OverendCommentary";
import CommonCommentary, { RunColors } from "./common/CommonCommentary";
import { getAssetUrl } from "src/helpers/general";
import { getSeriesMatchID } from "./common/helper";
import { getSanitizedArray } from "../../../../../helpers/general";
import { commentary } from "./common/ShimmerData";
import LiveAnimatedRedDot from "../../../../common/LiveAnimatedRedDot";
import { getMobileDetect } from "../../../../../constants";
import { useHorizontalScroll } from "../../../../../hooks/useHorizontalScroll";
import { FaChevronDown } from "react-icons/fa";

const liveIcon = getAssetUrl("red-dot.svg");
const nextIcon = getAssetUrl("matchInstance/nextIcon.svg");
const previousIcon = getAssetUrl("matchInstance/previcon.svg");
const six = getAssetUrl("matchInstance/six.svg");
const four = getAssetUrl("matchInstance/four.svg");
const wickets = getAssetUrl("matchInstance/wickets.svg");
const page_info = getAssetUrl("page_info.svg")

const FilterButtonTexts = {
  All: "all",
  Fours: "fours",
  Six: "sixes",
  Wickets: "wickets",
}

const Select = ({ name, value, onChange, options, selectRef }) => {
  return (
    <div ref={selectRef} className={`relative flex md:flex-shrink-0 h-10 md:h-auto w-[140px] md:w-[220px] border-2 rounded-xl px-2 font-bold ${value === "all" ? '' : 'text-[#3A32D1] bg-[#3255D11A] border-[#3A32D1]'}`}>
      <select
        name={name}
        id={name}
        className="bg-transparent rounded-xl w-full focus:outline-none cursor-pointer h-full text-sm md:text-base appearance-none"
        value={value}
        onChange={onChange}
      >
        <option value="all">All {name.charAt(0).toUpperCase() + name.slice(1)}</option>
        {options.sort((a, b) => a.name.localeCompare(b.name)).map((option, index) => (
          <option key={index} value={option.name}>{option.name}</option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
        <span className="text-[#000] text-[10px]"><FaChevronDown /></span>
      </div>
    </div>
  );
};

const FilterButton = ({ selectedButton, setSelectedButton, showFilterSelect, isMobile, selectedBatsman, setSelectedBatsman, selectedBowler, setSelectedBowler, batsmansOptions, bowlersOptions }) => {
  const containerRef = useRef();
  const bowlerSelectRef = useRef();
  useHorizontalScroll(containerRef, selectedButton);

  const handleFilterButtonClick = (filter) => {
    setSelectedButton(filter);
    setSelectedBatsman("all");
    setSelectedBowler("all");
  };

  const handleBatsmanChange = (value) => {
    setSelectedBatsman(value);
    if (value === "all" && selectedBowler === "all") {
      setSelectedButton(FilterButtonTexts.All)
    } else {
      setSelectedButton('')
    }
    if (!isMobile && bowlerSelectRef.current && bowlerSelectRef.current.scrollIntoView) {
      bowlerSelectRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }

  const handleBowlerChange = (value) => {
    setSelectedBowler(value);
    if (value === "all" && selectedBatsman === "all") {
      setSelectedButton(FilterButtonTexts.All)
    } else {
      setSelectedButton('')
    }
  }

  return (
    <div ref={containerRef} className="flex md:overflow-x-scroll hideScrollbar gap-2 md:gap-4 md:px-5">
      {showFilterSelect && isMobile ?
        <>
          {batsmansOptions && batsmansOptions.length > 0 ?
            <Select name="batsman" value={selectedBatsman} onChange={(e) => handleBatsmanChange(e.target.value)} options={batsmansOptions} /> : null
          }
          {bowlersOptions && bowlersOptions.length > 0 ?
            <Select name="bowler" value={selectedBowler} onChange={(e) => handleBowlerChange(e.target.value)} options={bowlersOptions} /> : null
          }
        </> :
        <>
          <div data-testid={FilterButtonTexts.All} className={`md:text-2xl border-2 rounded-xl px-4 text-sm md:px-12 py-2 font-bold flex md:flex-shrink-0 items-center ${selectedButton === FilterButtonTexts.All ? "text-[#3A32D1] bg-[#3255D11A] border-[#3A32D1]" : "bg-white"} cursor-pointer`} onClick={() => handleFilterButtonClick(FilterButtonTexts.All)}>All</div>
          <div data-testid={FilterButtonTexts.Fours} className={`flex md:flex-shrink-0 items-center border-2 rounded-xl pl-2 md:pl-6 font-bold ${selectedButton === FilterButtonTexts.Fours ? "text-[#3A32D1] border-[#3A32D1] bg-[#3255D11A]" : "bg-white"} cursor-pointer`} onClick={() => handleFilterButtonClick(FilterButtonTexts.Fours)}>
            <div className="text-[9px] md:text-base">
              <div className="text-[#4AA450] text-xs md:text-lg leading-3 md:leading-5">4</div> Fours
            </div>
            <div>
              <img src={four} alt="four" className="mr-1" />
            </div>
          </div>
          <div data-testid={FilterButtonTexts.Six} className={`flex md:flex-shrink-0 items-center border-2 rounded-xl pl-2 md:pl-6 font-bold ${selectedButton === FilterButtonTexts.Six ? "text-[#3A32D1] border-[#3A32D1] bg-[#3255D11A]" : "bg-white"} cursor-pointer`} onClick={() => handleFilterButtonClick(FilterButtonTexts.Six)}>
            <div className="text-[9px] md:text-base">
              <div className="text-[#985DD2] text-xs md:text-lg leading-3 md:leading-5">6</div> Sixes
            </div>
            <div>
              <img src={six} alt="six" className="mr-1" />
            </div>
          </div>
          <div data-testid={FilterButtonTexts.Wickets} className={`flex md:flex-shrink-0 items-center border-2 rounded-xl pl-2 md:pl-6 font-bold ${selectedButton === FilterButtonTexts.Wickets ? "text-[#3A32D1] border-[#3A32D1] bg-[#3255D11A]" : "bg-white"} cursor-pointer`} onClick={() => handleFilterButtonClick(FilterButtonTexts.Wickets)}>
            <div className="text-[9px] md:text-base">
              <div className="text-[#F24949] text-xs md:text-lg leading-3 md:leading-5">W</div> Wickets
            </div>
            <div>
              <img src={wickets} alt="wickets" className="mr-1" />
            </div>
          </div>
          {batsmansOptions && batsmansOptions.length > 0 && !isMobile ?
            <Select name="batsman" value={selectedBatsman} onChange={(e) => handleBatsmanChange(e.target.value)} options={batsmansOptions} /> : null
          }
          {bowlersOptions && bowlersOptions.length > 0 && !isMobile ?
            <Select selectRef={bowlerSelectRef} name="bowler" value={selectedBowler} onChange={(e) => handleBowlerChange(e.target.value)} options={bowlersOptions} /> : null
          }
        </>
      }
    </div>
  )
}

const Commentary = ({ liveBlogMatchId }) => {
  const [selectInnings, setSelectInnings] = useState("");
  const [inningToShow, setInningToShow] = useState({});
  const { seriesId: seriesSlug, matchesId: matchSlug } = useParams();
  const { matchId } = getSeriesMatchID(seriesSlug, matchSlug);
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [selectedButton, setSelectedButton] = useState(FilterButtonTexts.All)
  const [showFilterSelect, setSetshowFilterSelect] = useState(false);
  const [selectedBatsman, setSelectedBatsman] = useState("all");
  const [selectedBowler, setSelectedBowler] = useState("all");
  const md = getMobileDetect();
  const isMobile = Boolean(md.mobile());
  const {
    data,
    error: isError,
    isLoading,
    mutate
  } = useAxiosSWR(`v1/matches/${liveBlogMatchId || matchId}/commentary`, {
    refreshInterval,
  });
  const LiveMatchStatus = data && data?.match?.status === "Live";
  useEffect(() => {
    if (LiveMatchStatus) {
      setRefreshInterval(5000);
    } else {
      setRefreshInterval(0);
    }
  }, [LiveMatchStatus]);
  const infoData = isLoading ? commentary : data?.info || {};
  const inningsData = getSanitizedArray(infoData?.innings);
  useEffect(() => {
    inningsData.forEach((obj, index) => {
      obj.index = index;
    })
    if (!selectInnings) {
      setSelectInnings("Live");
      setInningToShow(inningsData.filter((i) => i.isLive == true)[0]);
    } else {
      selectInnings === "Live" ? setInningToShow(inningsData.filter((i) => i.isLive == true)[0]) :
        setInningToShow(inningsData.filter((i) => i.name == selectInnings)[0])
    }
  }, [inningsData]);

  const batsmansOptions = getSanitizedArray(inningToShow?.batsmen);
  const bowlersOptions = getSanitizedArray(inningToShow?.bowlers);

  if (isError) {
    return (
      <div className="flex flex-col items-center justify-center text-center mt-4">
        <h2 className="text-base">Something Went Wrong...</h2>
        <button onClick={mutate} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">Try Again</button>
      </div>
    );
  }
  const ShimmerClassName = infoData?.live_score?.isShimmer ? "bg-gray-300 loading text-opacity-0" : ""
  const liveTab = "Live";

  if (inningsData.length === 0 && data?.match?.status === 'Scheduled') {
    return (<div className="bg-white p-6 mx-2 md:mx-5 rounded-[14px]">
      <div className="flex items-center md:justify-start justify-center">
        <span className="font-bold">Ball by ball commentary comming soon..</span>
      </div>
    </div>)
  }

  return (
    <div className="">
      <div className="md:mt-5 mt-4 md:mx-5 md:flex-row md:bg-white md:py-5 md:rounded-2xl md:border">
        <div className="flex text-sm cursor-pointer overflow-auto hideScrollbar px-[2px] md:px-5">
          {[{ name: liveTab, teamLogo: liveIcon }, ...inningsData]
            .map(({ name, teamLogo }, index) => (
              <div
                key={name}
                className={`text-base flex items-center mx-1 px-1 py-1 rounded-[10px] ${name === selectInnings
                  // className={`text-base flex items-center mx-1 px-1 py-1 rounded-[10px] ${((name === "Live" && selectInnings === "Live") || (inningToShow?.index === index - 1 && selectInnings !== "Live"))
                  ? `font-bold bg-blue-700 text-white`
                  : "border-2 border-[#EEEEEF]  bg-white"
                  }`}
                onClick={() => {
                  setSelectInnings(name);
                  setSelectedButton(FilterButtonTexts.All);
                  setSelectedBatsman("all");
                  setSelectedBowler("all");
                  if (name == "Live") {
                    setInningToShow(inningsData.filter((i) => i.isLive == true)[0]);
                  } else {
                    setInningToShow(inningsData[index - 1]);
                  }
                }}
              >
                <div className="flex items-center text-xs md:text-base px-3">
                  {name === "Live" && (
                    <div className="h-[10px] w-[10px] mr-2">
                      {/* <img src={teamLogo} /> */}
                      <LiveAnimatedRedDot />
                    </div>
                  )}
                  <div>{name}</div>
                  {name !== "Live" && (
                    <div className="h-[30px] w-[30px] ml-2">
                      <img src={teamLogo} />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="mx-[6px] md:mx-0">
          {selectInnings == "Live" && (
            <LiveStats infoData={infoData} inningsData={inningToShow} />
          )}
        </div>
      </div>
      <div className="bg-white mx-[6px] md:mx-5 md:border-[#E3E3E4] border border-[#DAE6F8] rounded-2xl mt-4 md:mt-5 md:flex-row md:py-5 md:rounded-2xl ">
        <div className="relative overflow-x-auto md:border-none border-b border-[#DAE6F8] md:p-0 p-4">
          <FilterButton
            selectedButton={selectedButton}
            setSelectedButton={setSelectedButton}
            showFilterSelect={showFilterSelect}
            isMobile={isMobile}
            selectInnings={selectInnings}
            selectedBatsman={selectedBatsman}
            setSelectedBatsman={setSelectedBatsman}
            selectedBowler={selectedBowler}
            setSelectedBowler={setSelectedBowler}
            batsmansOptions={batsmansOptions}
            bowlersOptions={bowlersOptions}
          />
          {(batsmansOptions.length > 0 || bowlersOptions.length > 0) && inningToShow ?
            <div
              className="md:hidden flex items-center justify-end absolute top-1/2 right-0 -translate-y-1/2 w-14 h-full pr-3"
              style={{ background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.6) 0%, #fff 100%)' }}
              onClick={() => setSetshowFilterSelect(!showFilterSelect)}
            >
              <img src={page_info} alt="page_info" className={`w-6 h-6 ${showFilterSelect ? 'hidden' : 'block'}`} />
              <svg onClick={() => { setSelectedButton(FilterButtonTexts.All); setSelectedBatsman("all"); setSelectedBowler("all") }} className={`h-5 w-5 ${showFilterSelect ? 'block' : 'hidden'}`} fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div> : null
          }
        </div>
        <div className="px-2 md:px-0">
          <CommentaryByInnings
            data={inningToShow}
            ShimmerClassName={ShimmerClassName}
            selectedFilter={selectedButton}
            selectedBatsman={selectedBatsman}
            selectedBowler={selectedBowler}
          />
        </div>
      </div>
    </div>
  );
};

export default Commentary;

const getScore = (data, index) => {
  const score = [];
  data?.slice(index)?.some((obj) => {
    if (obj?.event !== "overend") {
      score.push(obj?.score);
      return false;
    } else {
      return true;
    }
  });
  return score;
};
const PAGE_SIZE = 20;
const CommentaryByInnings = ({ data, ShimmerClassName, selectedFilter, selectedBatsman, selectedBowler }) => {
  const commentaries = data?.commentaries || [];
  const filteredCommentries = useMemo(() => {
    let dataToShow = commentaries;
    switch (selectedFilter) {
      case FilterButtonTexts.Fours:
        dataToShow = commentaries.filter(o => o.score === 4)
        break;
      case FilterButtonTexts.Six:
        dataToShow = commentaries.filter(o => o.score === 6)
        break;
      case FilterButtonTexts.Wickets:
        dataToShow = commentaries.filter(o => o.score === 'w')
        break;
      default:
        break;
    }
    if (selectedBatsman !== "all") {
      dataToShow = dataToShow.filter(o => o.commentary.includes(selectedBatsman));
    }
    if (selectedBowler !== "all") {
      dataToShow = dataToShow.filter(o => o.commentary.includes(selectedBowler));
    }
    if (selectedBatsman !== "all" || selectedBowler !== "all") {
      dataToShow = dataToShow.filter(o => o.event !== "overend");
    }
    return dataToShow;
  }, [commentaries, selectedFilter, selectedBatsman, selectedBowler]);
  const teamName = data?.teamName;
  const containerRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setCurrentPage(1);
  }, [teamName]);
  const newData = useMemo(() => {
    const X = (filteredCommentries).slice(0, currentPage * PAGE_SIZE);
    return X;
  }, [filteredCommentries, currentPage, selectedFilter]);
  const handleSeeMoreClick = () => {
    setCurrentPage((prev) => {
      if (commentaries.length > currentPage * PAGE_SIZE) {
        return prev + 1;
      }
      return prev;
    });
  };
  return (
    <div className="md:mt-6 md:px-5 article-font" ref={containerRef}>
      <div>
        {newData.map((obj, index) => (
          <div key={index}>
            {obj.event === "overend" ? (
              <OverendCommentary
                data={obj}
                teamName={teamName}
                score={getScore(filteredCommentries, index + 1)}
                ShimmerClassName={ShimmerClassName}
              />
            ) : (
              <CommonCommentary data={obj} ShimmerClassName={ShimmerClassName} />
            )}
          </div>
        ))}
      </div>
      {newData.length === 0 && <div>No commentaries data for the selected filter</div>}
      <div className="text-center">
        {filteredCommentries.length > currentPage * PAGE_SIZE && (
          <button
            onClick={handleSeeMoreClick}
            className="text-blue-700 text-xs md:text-base py-2 md:font-medium"
          >
            See more
          </button>
        )}
      </div>
    </div>
  );
};


const LiveStats = ({ infoData, inningsData }) => {
  const containerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const nextSlide = () => {
    if (containerRef.current) {
      containerRef.current.scroll({
        left: containerRef.current.scrollLeft + 285,
        behavior: "smooth",
      });
    }
  };

  const prevSlide = () => {
    if (containerRef.current) {
      containerRef.current.scroll({
        left: containerRef.current.scrollLeft - 285,
        behavior: "smooth",
      });
    }
  };
  const handleScroll = () => {
    if (containerRef.current) {
      setShowLeftArrow(containerRef.current.scrollLeft > 0);
      setShowRightArrow(
        containerRef.current.scrollLeft <
        containerRef.current.scrollWidth - containerRef.current.clientWidth - 5
      );
    }
  };

  const formatReviewsRemaining = (reviewsRemaining) => {
    if (!reviewsRemaining || !Array.isArray(reviewsRemaining)) {
      return "";
    }

    return reviewsRemaining
      .map((teamReviews) => {
        const [team, remaining] = teamReviews.split(" - ");
        return `${team} - ${remaining}`;
      })
      .join("  •  ");
  };

  return (
    <>
      <div
        data-role="batter/bowler-container"
        className="md:flex md:gap-4 mt-6 md:px-5 "
      >
        {infoData?.batsmen ?
          <>
            <div className="md:w-[58%] border border-[#DAE6F8] rounded-xl bg-[#F9FBFF]">
              <div className="">
                <div className="border-b border-[#DAE6F8] bg-[#E8F1FF] rounded-t-xl">
                  <div className="text-left md:text-sm text-xs grid grid-cols-9 md:grid-cols-8 px-4 md:px-0">
                    <p className="flex justify-between items-center md:px-2 py-2 font-bold col-span-4 md:col-span-3 text-base">
                      Batter’s
                    </p>
                    <p className="flex justify-between items-center px-0.5 md:px-2 py-2 text-gray-500">
                      R
                    </p>
                    <p className="flex justify-between items-center px-0.5 md:px-2  py-2 text-gray-500">
                      B
                    </p>
                    <p className="flex justify-between items-center px-0.5 md:px-2 py-2 text-gray-500">
                      4s
                    </p>
                    <p className="flex justify-between items-center px-0.5 md:px-2 py-2 text-gray-500">
                      6s
                    </p>
                    <p className="flex justify-between items-center px-0.5 md:px-2 py-2 text-gray-500">
                      SR
                    </p>
                  </div>
                </div>
                <div>
                  {infoData?.batsmen?.map((batsman, index) => (
                    <div key={index} className={`grid grid-cols-9 md:grid-cols-8 text-sm px-4 md:px-0 ${index === 1 ? "" : "border-b border-[#DAE6F8]"} py-1 md:py-0`}>
                      <p className="flex justify-between items-center px-0.5 md:px-2 sm:text-sm text-xs py-2 font-bold col-span-4 md:col-span-3 md:underline">
                        {batsman.name} {batsman?.position == 'striker' ? '*' : ''}
                      </p>
                      <p className="flex justify-between items-center px-0.5 md:px-2 sm:text-sm text-xs py-2 md:font-semibold">
                        {batsman.runs}
                      </p>
                      <p className="flex justify-between items-center px-0.5 md:px-2 sm:text-sm text-xs py-2">
                        {batsman.balls_faced}
                      </p>
                      <p className="flex justify-between items-center px-0.5 md:px-2 sm:text-sm text-xs py-2">
                        {batsman.fours}
                      </p>
                      <p className="flex justify-between items-center px-0.5 md:px-2 sm:text-sm text-xs py-2">
                        {batsman.sixes}
                      </p>
                      <p className="flex justify-between items-center sm:text-sm text-xs px-0.5 md:px-2 py-2">
                        {batsman.strike_rate}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="overflow-x-auto">
                <div>
                  <div className="bg-[#E8F1FF]">
                    <div className="text-left sm:text-sm text-xs grid grid-cols-9 md:grid-cols-8 border-y border-[#DAE6F8] px-2 md:px-0">
                      <p className="flex justify-between items-center px-2 py-2 font-bold col-span-4 md:col-span-3 text-base">
                        Bowler’s
                      </p>
                      <p className="flex justify-between items-center px-0.5 md:px-2 py-2 text-gray-500">
                        O
                      </p>
                      <p className="flex justify-between items-center px-0.5 md:px-2 py-2 text-gray-500">
                        M
                      </p>
                      <p className="flex justify-between items-center px-0.5 md:px-2 py-2 text-gray-500">
                        R
                      </p>
                      <p className="flex justify-between items-center px-0.5 md:px-2 py-2 text-gray-500">
                        W
                      </p>
                      <p className="flex justify-between items-center px-0.5 md:px-2 py-2 text-gray-500">
                        Econ
                      </p>
                    </div>
                  </div>
                  <div>
                    {infoData?.bowlers?.map((bowler, index) => (
                      <div key={index} className={`grid grid-cols-9 md:grid-cols-8 text-sm px-2 md:px-0 ${index === 1 ? "" : "border-b border-[#DAE6F8]"} py-1 md:py-0`}>
                        <p className="flex justify-between items-center px-2 sm:text-sm text-xs py-2 font-bold col-span-4 md:col-span-3 md:underline">
                          {bowler.name} {bowler?.bowling == 'true' ? '*' : ''}
                        </p>
                        <p className="flex justify-between items-center sm:text-sm text-xs py-2 px-0.5 md:px-2 md:font-semibold">
                          {bowler.overs}
                        </p>
                        <p className="flex justify-between items-center sm:text-sm text-xs px-0.5 md:px-2 py-2">
                          {bowler.maidens}
                        </p>
                        <p className="flex justify-between items-center sm:text-sm text-xs px-0.5 md:px-2 py-2">
                          {bowler.runs_conceded}
                        </p>
                        <p className="flex justify-between items-center sm:text-sm text-xs px-0.5 md:px-2 py-2">
                          {bowler.wickets}
                        </p>
                        <p className="flex justify-between items-center py-2 px-0.5 md:px-2 sm:text-sm text-xs">
                          {bowler.econ}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <KeyStats infoData={infoData} forMoblie={false} formatReviewsRemaining={formatReviewsRemaining} />
          </>
          : "Commentary is comming soon ..."}
      </div>
      <div
        className={`${!inningsData ? "hidden" : ""
          } border md:border-[#DAE6F8] bg-white md:bg-[#F9FBFF] md:h-[76px] md:mt-8 mt-4 md:mx-5 rounded-xl flex items-center p-1.5 md:p-0`}
      >
        {showLeftArrow && (
          <img
            src={previousIcon}
            alt="previous Icon"
            className="cursor-pointer object-cover shadow-lg rounded-full mx-2 hidden md:block"
            onClick={prevSlide}
          />
        )}
        <div
          className="flex items-center gap-2 overflow-x-auto hideScrollbar h-full"
          onScroll={handleScroll}
          ref={containerRef}
        >
          <div className="md:p-2 p-1 md:text-sm text-[10px] text-[#3A32D1] font-bold border-r border-[#E3E3E4] px-3 md:px-2">This<br />Over</div>
          <div className="md:p-2 p-1 flex gap-2">
            <div className="flex items-center gap-2 text-[10px] md:text-sm text-gray-600">
              {inningsData?.commentaries?.map((commentary, idx) => (
                <div key={idx}>
                  {commentary.event === "overend" ? (
                    <div className="md:px-5 pl-2 md:pl-5 flex flex-col items-center justify-center border-l border-[#E3E3E4]">
                      <div className="">{commentary.over}</div>
                      <div className="">Ovr</div>
                    </div>
                  ) : (
                    <div
                      className={`text-white md:w-[30px] h-[24px] md:h-[30px] w-[24px] flex rounded-full text-[10px] md:text-sm justify-center items-center ${RunColors[commentary.score] || "bg-blue-700"
                        }`}
                    >
                      {commentary.score}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        {showRightArrow && (
          <img
            src={nextIcon}
            alt="Next Icon"
            className="cursor-pointer object-cover shadow-lg rounded-full mx-2 hidden md:block"
            onClick={nextSlide}
          />
        )}
      </div>
      <KeyStats infoData={infoData} forMoblie={true} formatReviewsRemaining={formatReviewsRemaining} />
    </>
  );
};

const KeyStats = ({ infoData, forMoblie, formatReviewsRemaining }) => {
  return (
    <div className={`md:w-2/5 md:mt-0 mt-4 ${forMoblie ? "md:hidden" : "hidden md:block"}`}>
      <div>
        <div className="border border-[#DAE6F8] text-left text-[12px] md:text-sm bg-[#F9FBFF] rounded-xl ">
          <p className="py-2 font-bold text-center md:text-left text-base md:text-sm bg-[#E8F1FF] rounded-t-xl lg:px-2 md:px-2 px-4 border-b border-[#DAE6F8]">
            Key Stats
          </p>
          <p className="flex justify-between items-center md:px-2 px-4 py-2.5 md:py-2 md:font-bold border-b border-[#DAE6F8] md:text-black text-[#6c6c6c]">
            Partnership:
            <span className="md:text-gray-500 font-medium md:font-normal text-black ">
              {infoData.key_stats.partnership && infoData.key_stats.partnership !== 'undefined(undefined)'
                ? infoData.key_stats.partnership
                : "-"}
            </span>
          </p>
          <p className="flex justify-between items-center px-4 md:px-2 py-2.5 md:py-2 md:font-bold border-b border-[#DAE6F8] md:text-black text-[#6c6c6c]">
            Last 5 Overs:
            <span className="md:text-gray-500 font-medium md:font-normal text-black ">
              {infoData.key_stats.last5_overs
                ? infoData.key_stats.last5_overs
                : "-"}
            </span>
          </p>
          <p className="flex justify-between items-center px-4 md:px-2 py-2.5 md:py-2 md:font-bold border-b border-[#DAE6F8] md:text-black text-[#6c6c6c] text-nowrap">
            Last Wicket:
            <span className="md:text-gray-500 font-medium md:font-normal ml-2 text-black text-wrap text-end">
              {infoData.key_stats.last_wicket
                ? infoData.key_stats.last_wicket
                : "-"}
            </span>
          </p>

          <p className="flex justify-between items-center px-4 md:px-2 py-2.5 md:py-2 md:font-bold border-b border-[#DAE6F8] md:text-black text-[#6c6c6c]">
            Toss:
            <span className="md:text-gray-500 font-medium md:font-normal text-black ">
              {infoData.key_stats.toss}
            </span>
          </p>
          <p className="flex justify-between items-center px-4 md:px-2 py-2.5 md:py-2 md:font-bold md:text-black text-[#6c6c6c]">
            Reviews Remaining:
            <span className="md:text-gray-500 font-medium md:font-normal text-black ">
              {formatReviewsRemaining(infoData.key_stats.reviews_remaining)}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}